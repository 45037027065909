var site = site || {};

site.client = site.client || {};

(function ($) {
  Drupal.behaviors.productComparisonV1 = {
    nodes: {},
    selector: {
      item: '.js-comparison--item',
      container: '.js-content-block--grid',
      field: '.js-comparison-field',
      fieldValue: '.js-comparison-field--value',
      product: '.js-product-ui',
      name: '.js-product-brief__name-link'
    },

    attach: function (context) {
      var self = this;

      if (self.attached) {
        return;
      }

      var $containers = $(self.selector.container, context);
      $containers.each(function () {
        var $this = $(this);
        self.getDom($this);
        self.setEvents($this);
        self.setup($this);
      });
      self.attached = true;
    },

    getDom: function ($that) {
      var self = this;

      $that.nodes = {};
      $that.nodes.$items = $(self.selector.item, $that);
      $that.nodes.$fields = $(self.selector.field, $that);
      $that.nodes.$groupedFields = {};
      $that.nodes.$fieldValues = $(self.selector.fieldValue, $that);
    },

    setup: function ($that) {
      var self = this;

      self.setFieldValues($that);
      self.groupFields($that);
      if (!$that.data('isSlider')) {
        // if wrapper is a slider, we match fields after the slider is initialised
        self.matchFieldsHeight($that);
      }
    },

    reInit: function ($that) {
      var self = this;

      self.getDom($that);
      self.setup($that);
    },

    groupFields: function ($that) {
      var self = this;
      var index = 0;
      $that.nodes.$fields.each(function () {
        var $this = $(this);
        var $parentItem = $this.closest($that.nodes.$items);
        index = $parentItem.find($that.nodes.$fields).index($this);
        $this.data('field-index', index);
        $that.nodes.$groupedFields[index] = !!$that.nodes.$groupedFields[index]
          ? $that.nodes.$groupedFields[index].add($this)
          : $this;
      });
    },

    matchFieldsHeight: function ($that) {
      var self = this;

      var $names = $that.nodes.$items.find(self.selector.name);
      self.setEqualHeights($names);

      Object.keys($that.nodes.$groupedFields).forEach(function (index) {
        $fields = $that.nodes.$groupedFields[index];
        self.setEqualHeights($fields);
      });
      $that.addClass('match-fields-height');
    },

    setEqualHeights: function ($elems) {
      if ($elems.hasClass('equal-height-in-progress')) {
        return;
      }
      $elems.addClass('equal-height-in-progress')
      var height = 0;
      // reset all inline heights first.
      $elems.css({ height: '' });
      $elems.each(function () {
        var $this = $(this);
        height = height > $this.height() ? height : $this.height();
      });
      $elems.css({ height: height }).addClass('match-element-height');
      $elems.removeClass('equal-height-in-progress');
    },

    setFieldValues: function ($that) {
      var self = this;

      var $fields = $that.nodes.$fieldValues.filter('[data-field]');
      var $item = $();
      var $field = $();
      var data = {};
      var value = '';
      var translatedValue = '';
      if ($fields.length < 1) {
        return;
      }

      $fields.each(function () {
        var $this = $(this);
        value = self.getFieldValue($that, $this) || '-';
        $this.html(value);
      });
    },

    getFieldValue: function ($that, $fieldValue) {
      var self = this;

      $item = $fieldValue.closest($that.nodes.$items);
      $field = $fieldValue.closest($that.nodes.$fields);

      if ($item.length < 1 || $field.length < 1) {
        return '-';
      }
      data =
        $field.data('fieldLevel') === 'sku'
          ? prodcat.data.getSku($item.data('skuBaseId'))
          : prodcat.data.getProduct($item.data('productId'));
      value = '-';
      if (!!data && data[$fieldValue.data('field')]) {
        value = data[$fieldValue.data('field')] || '-';
      }

      if (
        $fieldValue.data('field') &&
        $fieldValue.data('field').toLowerCase().indexOf('_checkboxes') > -1
      ) {
        // We need to use translations for this value.
        value = self.getTranslatedValue(value);
      }
      return value;
    },

    getTranslatedValue: function (value) {
      var translatedValue = '';
      var values = [];
      var translatedValues = [];
      var hasTranslations =
        !!site.translations &&
        !!site.translations.elc_product &&
        !!site.translations.elc_product_attributes;

      if (value.indexOf(',') > -1) {
        values = value.split(',');
      } else {
        values = [value];
      }
      values.forEach(function (val) {
        translatedValue = val;
        if (
          hasTranslations &&
          (site.translations.elc_product[val] !== '' ||
            !!site.translations.elc_product_attributes[val] !== '')
        ) {
          translatedValue =
            site.translations.elc_product[val] || site.translations.elc_product_attributes[val];
        }
        translatedValues.push(translatedValue);
      });
      return translatedValues.join(', ');
    },

    setEvents: function ($that) {
      var self = this;

      $(document).on('product.skuSelect', self.selector.product, function (e, skuBaseId) {
        var $product = $(this);
        var $fields = $product.find($that.nodes.$fields).filter('[data-field-level="sku"]');
        var value = '';
        var $fieldValue = $();
        if ($fields.length > 0) {
          $fields.each(function () {
            var $this = $(this);
            $fieldValue = $this.find($that.nodes.$fieldValues);
            value = self.getFieldValue($that, $fieldValue);

            $fieldValue.html(value);
          });
        }
      });
      Unison.on('change', function () {
        // only run matchHeight after initialisation
        if ($that.hasClass('match-fields-height')) {
          self.matchFieldsHeight($that);
        }
      });
      $that.on('slider-initialised', function () {
        // allow the slider to fully load before seting the fields height

        setTimeout(function () {
          // get dom / setup again to include cloned slider items
          self.reInit($that);
          self.matchFieldsHeight($that);
        }, 2000);
      });
      $that.on('slider-peeking-changed', function () {
        self.matchFieldsHeight($that);
      });
    },

    attached: false
  };
})(jQuery);
